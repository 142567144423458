import React from "react";
import {Badge, Button, Container} from "react-bootstrap";
import {Player} from "../entity/Player";
import {WordSet} from "../entity/WordSet";
import axios, {AxiosResponse} from "axios";
import {ManualComponent} from "./ManualComponent";
import "./RoundComponent.scss";
import {apiUrl} from '../index';

interface RoundComponentProps {
    playerList: Player[];
    readonly onFinish: () => void;
    readonly language: string;
}

export function RoundComponent(props: RoundComponentProps): React.ReactElement {
    const [words, setWords] = React.useState<number | undefined>(undefined);
    const [wordIndex, setWordIndex] = React.useState<number>(0);
    const [playerIndex, setPlayerIndex] = React.useState<number>(0);
    const [currentWord, setCurrentWord] = React.useState<WordSet | undefined>(undefined);
    const [categoryList, setCategoryList] = React.useState<string []>([]);
    const [currentCategory, setCurrentCategory] = React.useState<string | undefined>(undefined);
    const [manualOpen, setManualOpen] = React.useState<boolean>(false);

    const getCategories = React.useCallback(() => {
        axios.get(`${apiUrl}/category/${props.language}`)
            .then((response: AxiosResponse<string[]>) => response.data)
            .then(setCategoryList);
    }, [props.language, setCategoryList]);

    React.useEffect(() => {
        getCategories();
    }, [getCategories]);

    function howManyWords(): React.ReactElement {
        return <div className={"container-main"}>
            <div
                className={"how-many-words-text custom-container"}>{props.language === "PL" ? "Po ile słów gramy w tej rundzie?" : "How many words are we playing in this round?"}</div>
            <div>
                <Button variant="primary" className={"how-many-words-button"} onClick={() => setWords(3)}>3</Button>
                <Button variant="primary" className={"how-many-words-button"} onClick={() => setWords(5)}>5</Button>
                <Button variant="primary" className={"how-many-words-button"} onClick={() => setWords(8)}>8</Button>
            </div>
        </div>;
    }

    function playerGuessed(player: Player) {
        player.score++;
        nextWord();
    }

    function nextWord() {
        console.log(wordIndex + 1, words);
        if (wordIndex + 1 !== words) {
            setWordIndex(prevState => prevState + 1);
            getWordSet(currentCategory || "");
        } else {
            setPlayerIndex(prevState => prevState + 1);
            setWordIndex(0);
            setCurrentCategory(undefined);

            if (playerIndex + 1 === props.playerList.length) {
                props.onFinish();
            }
        }
    }

    function getWordSet(category?: string) {
        const route = (category && category !== "random") ? `word/${category}/1` : `word/1/${props.language}`;
        axios.get(`${apiUrl}/${route}`)
            .then((axiosResponse: AxiosResponse<WordSet []>) => axiosResponse.data[0])
            .then(setCurrentWord);
    }

    function render() {
        if (currentCategory === undefined) {
            return renderPlayer(props.playerList[playerIndex]);
        }
        return <Container className={"container-main"}>
            <div className={"custom-container"}>
                <div className={"main-word"}>{currentWord?.mainWord}</div>
                <div className={"forbidden-words"}>{currentWord?.forbiddenWords.map((word) => <Badge
                    bg="secondary forbidden-words">{word}</Badge>)}</div>
            </div>
            <div>
                {props.playerList.filter((x, i) => i !== playerIndex).map((x) =>
                    <Button
                        variant="primary"
                        className={"player-button"}
                        onClick={() => playerGuessed(x)}> {x.name} </Button>)}
                <br/>
                <Button
                    variant="primary"
                    className={"player-button"}
                    onClick={nextWord}>{props.language === "PL" ? "Poddajemy się" : "We give up"}</Button>
            </div>
        </Container>;
    }

    function renderPlayer(player: Player) {
        return <div className={"container-main"}>
            <div className={"player"}>{player.name}</div>
            <div className={"categories"}>
                {categoryList.map((x) => <Button variant="primary"
                                                 key={x}
                                                 className={"category-button"}
                                                 onClick={() => {
                                                     setCurrentCategory(x);
                                                     getWordSet(x);
                                                 }}>{x}</Button>)}
                <Button variant="primary"
                        className={"category-button"}
                        onClick={() => {
                            setCurrentCategory("random");
                            getWordSet();
                        }}>{props.language === "PL" ? "MIESZANE" : "MIXED"}</Button>
            </div>
        </div>;
    }

    return <div>
        <div>{words === undefined ? howManyWords() : render()}
            <ManualComponent show={manualOpen} onHide={() => setManualOpen(false)} language={"PL"}/>
        </div>
        <Button className={"button-manual"}
                onClick={() => setManualOpen(true)}>{props.language === "PL" ? "Jak grać?" : "How to play?"}</Button>
    </div>;

}
