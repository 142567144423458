import React from "react";
import "./App.scss";
import {PlayerListComponent} from "./component/PlayerListComponent";
import {RoundComponent} from "./component/RoundComponent";
import {LanguageComponent} from "./component/LanguageComponent";
import {ResultComponent} from "./component/ResultComponent";
import {Player} from "./entity/Player";

function App() {
    // return <PlayerListComponent></PlayerListComponent>;
    type Stage = "language" | "players" | "gameplay" | "result";

    const [stage, setStage] = React.useState<Stage>("language");
    const [language, setLanguage] = React.useState<string>("PL");
    const [players, setPlayers] = React.useState<Player[]>([]);

    function handleLanguage(language: string) {
        setLanguage(language);
        setStage("players");
    }

    function handlePlayers(playerList: Player[]) {
        setPlayers(playerList);
        setStage("gameplay");
    }


    switch (stage) {
        case "language": {
            return <LanguageComponent onLanguageSelect={(language) => handleLanguage(language)}></LanguageComponent>;
        }
        case "players": {
            return <PlayerListComponent onStart={(names) => handlePlayers(names)}
                                        language={language}></PlayerListComponent>;
        }
        case "gameplay": {
            return <RoundComponent playerList={players}
                                   onFinish={() => setStage("result")}
                                   language={language}></RoundComponent>;
        }
        case "result": {
            return <ResultComponent playerList={players}
                                    onClick={() => setStage("gameplay")}
                                    onClickFinish={() => setStage("language")}
                                    language={language}></ResultComponent>;
        }
    }
}

export default App;
