import React from "react";
import {Modal} from "react-bootstrap";
import "./ManualComponent.scss";
import {faCircleXmark} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface ManualComponentProps {
    readonly show: boolean;
    readonly onHide: () => void;
    readonly language: string;
}

export function ManualComponent(props: ManualComponentProps): React.ReactElement {

    function manualPl() {
        return <div className={"manual-text"}>
            <p>★ Gdy pierwszy gracz jest przed ekranem, wyświetla mu się słowo główne.<br/>
                Musi on wytłumaczyć pozostałym to słowo, bez użycia zakazanych słów - tych poniżej.<br/>
                ★ Osoba, która pierwsza odgadnie słowo dostaje punkt!<br/>
                ★ Jeśli drużyna ma problem z odgadnięciem słowa, może się poddać - wtedy punkt przepada.<br/>
                ★ Aby przejść do następnego słowa wybierz gracza, który odgadł.<br/>
                ★ Gracze tłumaczą po tyle słów, ile wybrali na początku rundy.<br/>
                ★ Po każdej rundzie jest możliwość zmiany ilości tłumaczonych słów.
            </p>
            <p><FontAwesomeIcon icon={faCircleXmark}/> Nie wolno zdradzać innym graczom wybranej kategorii!<br/>
                <FontAwesomeIcon icon={faCircleXmark}/> Nie można również używać słów pochodnych np. gdy hasło to
                "fryzjer", nie można użyć takiego słowa jak
                "fryzura".<br/>
                <FontAwesomeIcon icon={faCircleXmark}/> Nie można również gestykulować ani pokazywać!<br/>
                <FontAwesomeIcon icon={faCircleXmark}/> Nie używaj skrótów, gdy hasło to "komputer", nie można
                powiedzieć "PC"<br/>
                <FontAwesomeIcon icon={faCircleXmark}/> Odpowiedzi mają być skierowane do wszystkich, a nie do
                jednego gracza np. nie można powiedzieć "byliśmy tam ostatnio", chyba, że było się tam ze wszystkimi
                graczami :)
            </p>
            <p>Wersja Guess The Word level hard!<br/>★ Wytłumacz słowo używając tylko słów na literę "P".<br/>
                ★ Wydaje się szalone, ale jak już się rozruszasz, to niezła zabawa!</p>
        </div>;
    }

    function manualEn() {
        return <div className={"manual-text"}>
            <p>When the first player is in front of the screen, they are shown the main word.<br/>
                They must explain this word to the others, without using the prohibited words - those below.<br/>
                Do not reveal the chosen category to other players!<br/>
                You also cannot use derived words, for example, if the word is "hairdresser," you cannot use a word like
                "hairstyle."<br/>
                Gesturing or pointing is also not allowed!<br/>
                Do not use abbreviations; for example, if the word is "computer," you cannot say "PC."<br/>
                Answers should be directed to everyone, not just one player; for example, you cannot say "we were there
                last time" unless everyone was there :)The person who first guesses the word gets a point!<br/>
                If the team struggles to guess the word, they can give up - then the point is lost.<br/>
                To move on to the next word, select the player who guessed.<br/><br/>
                Players explain as many words as they chose at the beginning of the round.
                After each round, there is an option to change the number of words to be explained.</p>
            <br/>
            <p>Guess The Word hard level version!</p>
            <p>Explain the word using only words that start with the letter "P".<br/>
                It may seem crazy, but once you get into it, it's a lot of fun!</p>
        </div>;
    }

    return <Modal
        show={props.show}
        onHide={props.onHide}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
    >
        <Modal.Header closeButton className={"modal-manual"}>
            <Modal.Title
                id="example-custom-modal-styling-title"
                className={"headline"}>
                {props.language === "PL" ? "JAK GRAĆ?" : "HOW TO PLAY?"}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className={"modal-manual"}>
            {props.language === "PL" ? manualPl() : manualEn()}
        </Modal.Body>
    </Modal>;
}
