import {Player} from "../entity/Player";
import {Button} from "react-bootstrap";
import React from "react";
import "./ResultComponent.scss";


interface ResultComponentProps {
    readonly playerList: Player[];
    readonly onClick: () => void;
    readonly onClickFinish: () => void;
    readonly language: string;
}

export function ResultComponent(props: ResultComponentProps) {
    return <div className={"container-main"}>
        <div
            className={"custom-container result-container"}>
            <div className={"result-text"}>{props.language === "PL" ? "Tablica wyników:" : "Score board:"}</div>
            {props.playerList.map((x) =>
                <div>
                    <div className={"players"}>{x.name}</div>
                    <div className={"score"}>{x.score}</div>
                </div>)}</div>
        <div>
            <Button variant="primary"
                    className={"result-button"}
                    onClick={props.onClick}>{props.language === "PL" ? "Następna runda" : "Next round"}</Button>
            <Button variant="primary"
                    className={"result-button"}
                    onClick={props.onClickFinish}>{props.language === "PL" ? "Może później" : "Maybe later"}</Button>
        </div>
    </div>;
}
