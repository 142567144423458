import React from "react";
import {Button, Form, InputGroup} from "react-bootstrap";
import {Player} from "../entity/Player";
import "./PlayerListComponent.scss"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";

interface PlayerListComponentProps {
    readonly onStart: (playerList: Player[]) => void;
    readonly language: string;
}

export function PlayerListComponent(props: PlayerListComponentProps): React.ReactElement {
    const [playerList, setPlayerList] = React.useState<string[]>([]);

    function deletePlayer(index: number) {
        playerList.splice(index, 1);
        setPlayerList([...playerList]);
    }

    function handleChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, indexPlayer: number): void {
        playerList[indexPlayer] = event.target.value;
        setPlayerList([...playerList]);
    }

    function renderInput(text: string, index: number): React.ReactElement {
        return <InputGroup
            key={index}
            className="mb-3 custom-input">
            <Form.Control
                placeholder={props.language === "PL" ? "Gracz" : "Player"}
                aria-label={props.language === "PL" ? "Imię gracza" : "Player name"}
                value={text}
                onChange={(event) => handleChange(event, index)}
            />
            <Button variant="danger" id="basic-addon2" onClick={() => deletePlayer(index)}><FontAwesomeIcon icon={faTrash} /></Button>
        </InputGroup>;
    }

    return <div className={"playerList-container container"}>{playerList.map(renderInput)}
        <Button onClick={() => setPlayerList([...playerList, ""])}
                className={"playerList-button"}
                variant="primary">{props.language === "PL" ? "Dodaj gracza" : "Add player"}</Button>
        <Button variant="primary"
                disabled={playerList.filter(Boolean).length < 2}
                className={"playerList-button"}
                onClick={() => props.onStart(playerList
                    .filter(Boolean)
                    .map((x) => ({
                        name: x,
                        score: 0
                    })))}>{props.language === "PL" ? "Zaczynamy!" : "Let's play!"}</Button>
    </div>;
}
