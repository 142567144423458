import React from "react";
import {Button} from "react-bootstrap";
import axios, {AxiosResponse} from "axios";
import "./LanguageComponent.scss";
import {apiUrl} from '../index';

interface LanguageComponentProps {
    readonly onLanguageSelect: (language: string) => void;
}

export function LanguageComponent(props: LanguageComponentProps): React.ReactElement {
    const [languages, setLanguages] = React.useState<string[]>([]);

    React.useEffect(() => {
        getLanguages();
    }, []);

    function getLanguages() {
        axios.get(`${apiUrl}/language`)
            .then((response: AxiosResponse<string[]>) => response.data)
            .then(setLanguages);
    }

    function renderLanguage(language: string, index: number) {
        return <Button
            key={index}
            className={"language-button"}
            variant="primary"
            onClick={() => props.onLanguageSelect(language)}
        >{language === "PL" ? "Polski" : "English"}</Button>;
    }
    return <div className={"language-container container"}>{languages.map(renderLanguage)}</div>;
}
